import React, { ChangeEvent, useState } from "react";
import { Modal } from "react-bootstrap";
import Search from "../Search";
import "./contractPopup.scss";
import {
  SzCheckbox,
  SzSpinner,
  SzButton,
  SzSelect,
  SzInput,
} from "@suezenv/react-theme-components";
import {
  filterContract,
  ILoading,
  metadataFilterContract,
} from "../../../types";
import { config } from "../../../config";
interface IContractPopUp {
  loading: ILoading;
  clients: any;
  contracts: any;
  contractAreas: any;
  show: boolean;
  handleClose: () => void;
  addUserContract: (contractId: string, areas: string[]) => void;
  searchContracts: (
    query: string,
    clientId: string | null,
    metadata: metadataFilterContract | null
  ) => void;
  getArea: (contractId: string) => void;
}

interface IState {
  areas: string[];
}
const ContractPopup = (props: IContractPopUp) => {
  const defaultFilter = {
    clientId: null,
    contract: "",
    metadata: null,
  };
  const [filter, setFilter] = useState<filterContract>(defaultFilter);
  const [selectedContract, setselectedContract] = useState("");
  const [checkedArea, setcheckedArea] = useState<IState>({
    areas: [],
  });
  const metadataKey = config.contractMetadataKey;
  const handleCheck = (id: string, checked: boolean) => {
    let tmp = checkedArea.areas;
    if (checked) {
      tmp.push(id);
      setcheckedArea({ areas: tmp });
    } else {
      tmp.splice(tmp.indexOf(id), 1);
      setcheckedArea({ areas: tmp });
    }
  };
  const getContractTitle = (contract: any) => {
    let title = contract.label;
    if (!config.contractMetadataKey) {
      return title;
    }
    const metadata = contract.contractMetadatas.filter(
      (contactMetadata: any) =>
        contactMetadata.key === config.contractMetadataKey
    );
    if (metadata.length > 0) {
      title += ` ( ${metadata[0].value} )`;
    }

    return title;
  };
  return (
    <Modal
      dialogClassName=" h-100 contract-modal"
      size="xl"
      show={props.show}
      onHide={props.handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un contrat à un utilisateur </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-6">
            <SzSelect
              className="mb-3"
              id="id-2"
              isClearable
              isSearchable
              name="input-2"
              onChange={(item: any) => {
                setFilter({ ...filter, clientId: item });
              }}
              options={props.clients.map((client: any) => {
                return { label: client.label, value: client.id };
              })}
              placeholder="Identifiant de client"
              value={filter.clientId}
            />
          </div>
          <div className="col-6">
            <SzInput
              name="contract"
              value={filter.contract}
              placeholder="Contrats"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setFilter({ ...filter, contract: event.target.value })
              }
            />
          </div>
        </div>
        <div className="row justify-content-between">
          {metadataKey && (
            <div className="col-6">
              <SzInput
                name="metadata"
                placeholder={`Metadata: ${metadataKey}`}
                value={filter.metadata?.value}
                type="text"
                onChange={(event) => {
                  setselectedContract("");
                  setcheckedArea({ areas: [] });
                  setFilter({
                    ...filter,
                    metadata: { key: metadataKey, value: event.target.value },
                  });
                }}
              />
            </div>
          )}
          <div className="col">
            <div className="row justify-content-end">
              <div className="col-auto">
                <SzButton
                  className="mt-0"
                  variant="secondary"
                  type="submit"
                  onClick={() => {
                    setFilter(defaultFilter);
                    setselectedContract("");
                    setcheckedArea({ areas: [] });
                    props.searchContracts(
                      defaultFilter.contract,
                      null,
                      defaultFilter.metadata
                    );
                  }}
                  icon="close"
                />
              </div>
              <div className="col-auto m-0 pl-0">
                <SzButton
                  className="mt-0"
                  variant="primary"
                  type="submit"
                  onClick={() => {
                    props.searchContracts(
                      filter.contract,
                      filter.clientId ? filter.clientId.value : null,
                      filter.metadata
                    );
                  }}
                  icon="filter-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className=" contracts-content row pt-3 pl-2">
          <div className="col">
            <div className="row ">
              <div className="col-auto">
                <label className="sub-title"> liste des contrats:</label>
              </div>
            </div>
            {props.loading.loadingContract ? (
              <div className="row justify-content-center">
                <div className="col-auto">
                  <SzSpinner variant="primary" />
                </div>
              </div>
            ) : props.contracts.length === 0 ? (
              <div className="row justify-content-center">
                <div className="col-auto">
                  <label> aucun contrat trouvé</label>
                </div>
              </div>
            ) : (
              props.contracts.map((contract: any) => (
                <SzButton
                  className={
                    contract.id === selectedContract
                      ? "mb-1 selectedContract"
                      : "mb-1"
                  }
                  key={contract.id}
                  onClick={() => {
                    setcheckedArea({ areas: [] });
                    props.getArea(contract.id);
                    setselectedContract(contract.id);
                  }}
                >
                  {getContractTitle(contract)}
                </SzButton>
              ))
            )}
          </div>
          <div className="col">
            <div className="row ">
              <div className="col-auto">
                <label className="sub-title"> liste des emplacements:</label>
              </div>
            </div>
            {props.loading.loadingContractArea ? (
              <div className="row justify-content-center">
                <div className="col-auto">
                  <SzSpinner variant="primary" />
                </div>
              </div>
            ) : props.contracts.length > 0 &&
              props.contractAreas.length === 0 ? (
              selectedContract === "" ? (
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <label> aucun contrat séléctionné</label>
                  </div>
                </div>
              ) : (
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <label>
                      {" "}
                      pas d'emplacement pour le contrat séléctionné
                    </label>
                  </div>
                </div>
              )
            ) : (
              props.contractAreas.map((contractArea: any) => (
                <SzCheckbox
                  name={contractArea.area.label}
                  key={contractArea.id}
                  label={contractArea.area.label}
                  checked={checkedArea.areas.includes(contractArea.id)}
                  onChange={(event: any) =>
                    handleCheck(contractArea.id, event.target.checked)
                  }
                  type="checkbox"
                />
              ))
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <SzButton
          onClick={() => {
            setFilter(defaultFilter);
            setselectedContract("");
            setcheckedArea({ areas: [] });
            props.handleClose();
            props.addUserContract(selectedContract, checkedArea.areas);
          }}
        >
          Ajouter
        </SzButton>
      </Modal.Footer>
    </Modal>
  );
};
export default ContractPopup;
