import Request from "../services/Request";
import { IRightsAssignment } from '../components/rightsAssignment';
import { loadingSpinner } from './utils';

export  const  assignUserRights =  (body: IRightsAssignment) => {

    return (dispatch: any) => {
        dispatch(loadingSpinner(true));

        return  Request.post("/user/assign-rights", body)
            .then((response: any) => {
                dispatch(loadingSpinner(false));
                return response;
            })
            .catch((err) => {
                dispatch(loadingSpinner(false));
                console.error('err', err);
                if(typeof err === "object") { throw err; }
                throw new Error(err);
            });
    };
};