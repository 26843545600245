import React from "react";
import "./messageBox.scss";

export interface IMessageBox {
    message: string,
    type?: string
}

export const MessageBox = (props: IMessageBox) => {

    const { message='', type='infoBox' } = props;

    const classes = ['row', 'messageBox'];
    classes.push(type);

    return (
        <div className={classes.join(' ')}>
            <p>{message}</p>
        </div>
    );
};