import React, { useState } from "react";
import ContractCollapse from "../elements/collapse";
import "./userContracts.scss";
import ContractPopup from "../elements/contractPopup";
import { ILoading, metadataFilterContract } from "../../types";
import { SzIcon, SzButton, SzModal } from "@suezenv/react-theme-components";
import GoTo from "../elements/goTo";
import { Routes } from "../../constants";
import DeleteModalBody from "../elements/deleteModalBody";
import { config } from "../../config";
interface IContract {
  loading: ILoading;
  currentUser: any;
  userContracts: any;
  clients: any;
  contracts: any;
  contractAreas: any;
  userContractService: any;
  userContractServiceOptions: any;
  contractServiceOption: any;
  contractService: any;
  userContractServiceSavedList: any;
  saveUserContract: (body: any, userContractServiceId?: string) => Promise<any>;
  addUserContract: (contractId: string, areas: string[]) => void;
  searchContracts: (
    query: string,
    clientId: string | null,
    metadata: metadataFilterContract | null
  ) => void;
  getArea: (contractId: string) => void;
  getContractDetails: (contractId: string, userContractId: string) => void;
  getUserContractServiceOptions: (contractId: string) => void;
  updateUserContract: (value: any) => any;
  deleteUserContract: (userContractID: string) => any;
}
function UserContrats(props: IContract) {
  const [openC, setOpenC] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const defaultUserContract = {
    id: "",
    contract: {
      id: "",
      label: "",
    },
  };
  // contractServiceOptions
  const [deleteModalData, setDeleteModalData] = useState<any>({
    showModal: false,
    userContract: defaultUserContract,
  });
  const formatUserContractService = (userContractService: any) => {
    if (userContractService && userContractService.length !== 0) {
      return userContractService[0].contractServices;
    }
    return [];
  };
  const contractTitle = (contract: any) => {
    let title = contract.label;
    if (!config.contractMetadataKey) {
      return title;
    }
    const metadata = contract.contractMetadatas.filter(
      (contactMetadata: any) =>
        contactMetadata.key === config.contractMetadataKey
    );
    if (metadata.length > 0) {
      title += ` ( ${metadata[0].value} )`;
    }

    return title;
  };
  const saveUserContract = (
    body: any,
    contractAreaBody: any,
    isPost: boolean
  ) => {
    props.updateUserContract(contractAreaBody);
    if (isPost) {
      return props.saveUserContract(body);
    }
    body["id"] = props.userContractService[0].id;
    return props.saveUserContract(body, props.userContractService[0].id);
  };
  const renderModalBody = () => {
    const user = props.currentUser;
    const userContract = deleteModalData.userContract;
    return (
      <DeleteModalBody
        title={`Voulez vous vraiment désassigner le contrat ${userContract.contract.label} de l'utilisateur ${user.lastName} ${user.firstName}?`}
        onClickCancel={() => {
          setDeleteModalData({
            showModal: false,
            userContract: defaultUserContract,
          });
        }}
        onClickDelete={() => {
          props.deleteUserContract(userContract.id);
          setDeleteModalData({
            showModal: false,
            userContract: defaultUserContract,
          });
        }}
      ></DeleteModalBody>
    );
  };
  return (
    <>
      <GoTo
        link={Routes.usersList}
        title="Retour à la liste des utilisateurs"
      ></GoTo>
      <div className="row mb-3 justify-content-between">
        <div className="col-auto">
          <h1 className="title">{`Liste des contracts assignés à ${
            props.currentUser.id
              ? props.currentUser.lastName + " " + props.currentUser.firstName
              : ""
          }`}</h1>
        </div>
      </div>
      <div>
        {props.userContracts.length > 0 ? (
          props.userContracts.map((element: any) => (
            <div
              key={element.id}
              className="userContracts row  justify-content-center"
            >
              <div className="col-10">
                <SzButton
                  className="w-100 sub-title pt-0 pb-0 "
                  onClick={() => {
                    if (!props.loading.loadingContractService) {
                      if (openC === element.id) {
                        setOpenC("");
                      } else {
                        setOpenC(element.id);
                        props.getContractDetails(
                          element.contract.id,
                          element.id
                        );
                      }
                    }
                  }}
                  aria-controls="cities-collapse"
                  aria-expanded={openC}
                  icon={
                    "pr-4 float-right " +
                    (element.id === openC ? "arrow-up-1" : "arrow-down-1")
                  }
                >
                  {contractTitle(element.contract)}
                </SzButton>
                {openC === element.id && (
                  <ContractCollapse
                    key={element.id}
                    save={saveUserContract}
                    userContract={element}
                    contractAreas={props.contractAreas}
                    contractService={props.contractService}
                    userContractService={
                      element.id === openC
                        ? formatUserContractService(props.userContractService)
                        : []
                    }
                    getDetails={() => {
                      if (!props.loading.loadingContractService) {
                        props.getContractDetails(
                          element.contract.id,
                          element.id
                        );
                      }
                    }}
                    userContractServiceOptions={
                      props.userContractServiceOptions
                    }
                    userContractServiceSavedList={
                      props.userContractServiceSavedList
                    }
                    contractServiceOption={props.contractServiceOption}
                    open={element.id === openC}
                    loading={props.loading.loadingContractService}
                  ></ContractCollapse>
                )}
              </div>
              <div className="col-auto">
                <SzButton
                  variant="secondary"
                  className="danger sub-title pt-0 pb-0 "
                  onClick={() => {
                    setDeleteModalData({
                      showModal: true,
                      userContract: element,
                    });
                  }}
                  icon="bin-2-alternate"
                />
              </div>
            </div>
          ))
        ) : (
          <div className=" h-100 userContracts">
            <div className="contrats row  h-75 justify-content-around align-items-center">
              <div className="col-8">
                <h1 className="text-center">
                  {" "}
                  Pas de contrat pour cet utilisateur
                </h1>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row add-icon justify-content-around align-items-center">
        <div className="col-auto p-0">
          <SzButton
            className="btn add-button"
            onClick={() => {
              handleShow();
              props.searchContracts("", "", null);
            }}
          >
            <SzIcon icon="add-circle" variant="line" />
          </SzButton>
        </div>
      </div>
      <ContractPopup
        loading={props.loading}
        contracts={props.contracts}
        clients={props.clients}
        contractAreas={props.contractAreas}
        show={show}
        handleClose={handleClose}
        addUserContract={props.addUserContract}
        searchContracts={props.searchContracts}
        getArea={props.getArea}
      ></ContractPopup>
      <SzModal
        title={"désassigner un contract d'un utilisateur"}
        show={deleteModalData.showModal}
        handleClose={() =>
          setDeleteModalData({
            showModal: false,
            userContract: defaultUserContract,
          })
        }
        size={"lg"}
        className="danger"
      >
        {renderModalBody()}
      </SzModal>
    </>
  );
}
export default UserContrats;
