import React, { useState } from 'react';
import { assignUserRights } from '../../actions';
import { connect } from 'react-redux';
import { RightsAssignment, IRightsAssignment } from '../../components/rightsAssignment';
import { withRouter } from 'react-router-dom';
import Loading from '../../components/elements/loading';
import { MessageBox, IMessageBox } from '../../components/elements/messageBox';

const RightsAssignmentManager =  (props: any) => {

    const [messageBox, setMessageBox] = useState<IMessageBox>({
        type: undefined,
        message: ''
    });

    const assignUserRightsHandler = async (user: IRightsAssignment) => {

        try {
            const result = await props.assignUserRights(user);
            if(result?.data) {
                setMessageBox({ type: 'successBox', message: result.data});
            }
            else {
                setMessageBox({ type: 'successBox', message: 'Opération réalisée avec succès !'});
            }
        }
        catch(err: any) {
            let response = err.response?.data ? err.response.data : "Une erreur est survenue lors de l'oppération";
            if(typeof response === 'object' && 'errors' in response) {
                response = response.errors;
            }
            
            setMessageBox({ type: 'dangerBox', message: response});
        }
    };

    return (
        <>
            {props.loading.loading && <Loading></Loading>}
            {props.logged && (
                <div className="container mt-5">
                    <RightsAssignment
                        title={"Copie des droits d'un utilisateur vers un autre"}
                        assignUserRights={assignUserRightsHandler}
                    ></RightsAssignment>
                    {messageBox.type && <MessageBox {...messageBox} />}
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.loading,
    logged: state.auth.logged,
});

const mapDispatchToProps = {
    assignUserRights
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RightsAssignmentManager)
);
