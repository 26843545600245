import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import './App.scss';
import { store } from './store';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import UsersManager from './manager/users';
import userContracts from './manager/userContracts';
import UsersImportManager from './manager/usersImport';
import RightsAssignmentManager from './manager/rightsAssignment';
import { AuthenticateService } from './services/AuthenticateService';
import { Constants } from './constants';
import { AuthMode } from './types';
import { config } from './config';
import { useTheme } from "@suezenv/react-theme-components";
import { NavBar } from './components/elements/navBar';

interface IAppProps {
  history: History;
}

const AppRouter = ({ history }: IAppProps) => {
  useTheme("EDTM");
  useEffect(() => {
    if (config.authMode === AuthMode.TOKEN_MODE) {
      const token = localStorage.getItem('token-heimdall');
      if (!token || token === 'undefined') {
        AuthenticateService.login();
      } else {
        store.dispatch({
          payload: {
            authMode: AuthMode.TOKEN_MODE,
            token: token,
            logged: true
          },
          type: Constants.LOG_IN
        });
      }
    } else if (config.authMode === AuthMode.DIRECT_MODE) {
      ///Nothing to do
      store.dispatch({
        payload: {
          authMode: AuthMode.DIRECT_MODE,
          token: '',
          logged: true
        },
        type: Constants.LOG_IN
      });
    } else {
      AuthenticateService.checkLoggedIn();
    }
  }, [history]);
  return (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <div className="container mt-5">
                <NavBar />
            </div>
            <Switch>
                <Route exact path="/utilisateurs" component={UsersManager} />
                <Route
                exact
                path="/utilisateurs/ajouter"
                component={UsersManager}
                />
                <Route
                exact
                path="/utilisateurs/:id/contrats"
                component={userContracts}
                />
                <Route
                exact
                path="/utilisateurs/modifier/:id"
                component={UsersManager}
                />
                <Route
                exact
                path="/utilisateurs/importer"
                component={UsersImportManager}
                />
                <Route
                exact
                path="/utilisateurs/copie-droits"
                component={RightsAssignmentManager}
                />
                <Route render={() => <Redirect to="/utilisateurs" />} />
            </Switch>
        </ConnectedRouter>
    </Provider>
  );
};

export default AppRouter;
