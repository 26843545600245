import React, { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import "./collapse.scss";
import {
  SzSpinner,
  SzButton,
  SzCheckbox,
  SzSelect,
} from "@suezenv/react-theme-components";
interface IContract {
  userContract: any;
  contractAreas: any;
  contractService: any;
  userContractService: any;
  userContractServiceOptions: any;
  contractServiceOption: any;
  userContractServiceSavedList: any;
  getDetails: Function;
  save: (
    userContractServicebody: any,
    contractAreaBody: any,
    isPost: boolean
  ) => Promise<any>;
  open: boolean;
  loading?: boolean;
}
interface IState {
  userContractService: string[];
}
interface IOption {
  userContractServiceOptions: string[];
}
function ContractCollapse(props: IContract) {
  const [openC] = useState(true);
  const [saving, setSaving] = useState(false);
  const [checkedContractService, setCheckedContractService] = useState<any>({
    userContractService: [],
  });
  const [checkedContractServiceOption, setCheckedContractServiceOption] =
    useState<any>({ userContractServiceOptions: [] });

  const [contractArea, setContractArea] = useState<any>({
    contractAreas: [],
    selectedContractArea: [],
  });
  const [SzSelectContractArea, setSzSelectContractArea] = useState<any>([]);
  useEffect(() => {
    setCheckedContractService({
      userContractService:
        props.userContractService.length > 0
          ? props.userContractService.map(
              (contractService: any) => contractService.id
            )
          : [],
    });
  }, [props.userContractService, props.loading]);
  useEffect(() => {
    setContractArea({
      ...contractArea,
      contractAreas: props.contractAreas
        .map((contractArea: any) => {
          return { label: contractArea.area.label, value: contractArea.id };
        })
        .filter((item: any) => {
          return !contractArea.selectedContractArea
            .map((element: any) => element.value)
            .includes(item.value);
        }),
    });
  }, [props.contractAreas, contractArea.selectedContractArea, props.loading]);
  useEffect(() => {
    setContractArea({
      ...contractArea,
      selectedContractArea: props.userContract.contractAreas.map(
        (contractArea: any) => {
          return { label: contractArea.area.label, value: contractArea.id };
        }
      ),
    });
  }, [props.userContract.contractAreas, props.loading]);
  useEffect(() => {
    setCheckedContractServiceOption({
      userContractServiceOptions:
        props.userContractServiceSavedList.length > 0
          ? props.userContractServiceSavedList[0].contractServiceOptions.map(
              (contractService: any) => contractService.id
            )
          : [],
    });
  }, [props.userContractServiceSavedList, props.loading]);

  const save = () => {
    const value = {
      userContract: props.userContract.id,
      contractServices: checkedContractService.userContractService,
      contractServiceOptions:
        checkedContractServiceOption.userContractServiceOptions,
    };
    const value1 = {
      id: props.userContract.id,
      user: props.userContract.user,
      contract: props.userContract.contract.id,
      contractAreas: contractArea.selectedContractArea.map(
        (item: any) => item.value
      ),
    };
    setSaving(true);
    props
      .save(value, value1, props.userContractService.length === 0)
      .then(() => {
        setSaving(false);
        props.getDetails();
      });
  };
  const handleCheck = (id: string, checked: boolean) => {
    let tmp = checkedContractService.userContractService;
    if (checked) {
      tmp.push(id);
      setCheckedContractService({ userContractService: tmp });
    } else {
      tmp.splice(tmp.indexOf(id), 1);
      setCheckedContractService({ userContractService: tmp });
    }
  };
  const handleCheckOptions = (id: string, checked: boolean) => {
    let tmp = checkedContractServiceOption.userContractServiceOptions;
    if (checked) {
      tmp.push(id);
      setCheckedContractServiceOption({ userContractServiceOptions: tmp });
    } else {
      tmp.splice(tmp.indexOf(id), 1);
      setCheckedContractServiceOption({ userContractServiceOptions: tmp });
    }
  };
  return (
    <div key={props.userContract} className="collapse-container ml-2 mr-2">
      <Collapse in={props.open}>
        {!props.loading ? (
          <div className="collapse row mt-3 mt-3 ml-1 mr-1">
            <div className="col">
              <div className="row">
                <div className="col-6">
                  {props.contractService.length !== 0 ? (
                    [
                      ...new Set(
                        props.contractService.map(
                          (item: any, index: number) => item.service.portal.code
                        )
                      ),
                    ]
                      .map((portalCode: any) => {
                        return props.contractService.find(
                          (item: any) => item.service.portal.code === portalCode
                        );
                      })
                      .map((contractService: any) => (
                        <div key={props.userContract + contractService.id}>
                          <h1 className=" mb-3">
                            {"Portail : " +
                              contractService.service.portal.code +
                              " (" +
                              contractService.service.portal.shortDescription +
                              ")"}
                          </h1>
                          {props.contractService
                            .filter((item: any) => {
                              return (
                                item.service.portal.code ===
                                contractService.service.portal.code
                              );
                            })
                            .map((filtredContractService: any) => (
                              <div
                                key={
                                  props.userContract + filtredContractService.id
                                }
                                className="ml-3 row mb-2"
                              >
                                <div className="col-auto">
                                  <SzCheckbox
                                    name={filtredContractService.core}
                                    id={
                                      props.userContract.id +
                                      filtredContractService.id
                                    }
                                    key={
                                      props.userContract.id +
                                      filtredContractService.id
                                    }
                                    label={filtredContractService.service.label}
                                    checked={checkedContractService.userContractService.includes(
                                      filtredContractService.id
                                    )}
                                    onChange={(event: any) =>
                                      handleCheck(
                                        filtredContractService.id,
                                        event.target.checked
                                      )
                                    }
                                    type="checkbox"
                                  />
                                  <div className="m-3">
                                    {props.contractServiceOption.map(
                                      (item: any) => (
                                        <SzCheckbox
                                          key={item.id}
                                          name={item.core}
                                          id={item.id}
                                          label={item.serviceOption.label}
                                          checked={checkedContractServiceOption.userContractServiceOptions.includes(
                                            item.id
                                          )}
                                          onChange={(event: any) =>
                                            handleCheckOptions(
                                              item.id,
                                              event.target.checked
                                            )
                                          }
                                          type="checkbox"
                                          className="m-2"
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      ))
                  ) : (
                    <div className="ml-3 row">
                      <div className="col">la liste des services est vide</div>
                    </div>
                  )}
                </div>
                <div className="col-6">
                  <h1> liste des emplacements :</h1>
                  <div className="row ">
                    <div className="col-9 pr-0">
                      <SzSelect
                        className="mb-3"
                        id="id-2"
                        isClearable
                        isMulti
                        isSearchable
                        name="input-2"
                        onChange={(item: any) => {
                          setSzSelectContractArea(item);
                        }}
                        options={contractArea.contractAreas}
                        placeholder="placeholder"
                        value={SzSelectContractArea}
                      />
                    </div>
                    <div className="col-3 pl-0">
                      <SzButton
                        className="pr-0 no-hover"
                        isDisabled={SzSelectContractArea.length === 0}
                        icon={"add-square"}
                        onClick={() => {
                          setContractArea({
                            ...contractArea,
                            selectedContractArea:
                              contractArea.selectedContractArea.concat(
                                SzSelectContractArea
                              ),
                          });
                          setSzSelectContractArea([]);
                        }}
                      />
                    </div>
                  </div>
                  {contractArea.selectedContractArea !== 0 ? (
                    contractArea.selectedContractArea.map((item: any) => (
                      <div
                        key={item.value}
                        className="row ml-3 align-items-center"
                      >
                        <div className="col-9">
                          <li> {item.label} </li>
                        </div>
                        <div className="col">
                          <SzButton
                            className="transparent pr-0 no-hover"
                            onClick={() => {
                              setContractArea({
                                ...contractArea,
                                selectedContractArea:
                                  contractArea.selectedContractArea.filter(
                                    (selectedContractArea: any) => {
                                      return (
                                        selectedContractArea.value !==
                                        item.value
                                      );
                                    }
                                  ),
                              });
                            }}
                            icon="remove"
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <div className="row ml-3">
                        <div className="col">
                          <label> pas d'emplacements </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-3 ">
                <div className="col-auto">
                  <SzButton onClick={save} loader={saving} icon="floppy-disk">
                    Enregister
                  </SzButton>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="collapse row m-3 justify-content-center">
            <div className="col-auto">
              <SzSpinner variant="primary" />
            </div>
          </div>
        )}
      </Collapse>
    </div>
  );
}
export default ContractCollapse;
