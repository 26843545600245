import Request from "../services/Request";
import { loadingSpinner } from './utils';

export  const  importUsers =  (body: FormData) => {
    return (dispatch: any) => {
        dispatch(loadingSpinner(true));

        return  Request.postFile("/import/users", body)
            .then((response: any) => {
                dispatch(loadingSpinner(false));
                return response;
            })
            .catch((err) => {
                dispatch(loadingSpinner(false));
                console.error('err', err);
                if(typeof err === "object") { throw err; }
                throw new Error(err);
            });
    };
};