import React from "react";
import "./loading.scss";

const Loading = (props: any) => {
    return (
        <div className="loading-state">
            <div className="loading"></div>
        </div>
    );
}

export default Loading;