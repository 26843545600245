import { Constants } from '../constants';
import Request from '../services/Request';
import { loadingSpinner } from './utils';
import { AuthMode } from '../types';

export const getAllUsers = (page?: number, searchTerm?: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(loadingSpinner(true));
    const state = getState();
    return Request.get(
      '/user?itemsPerPage=' + (!state.users.itemsPerPage
        ? Constants.LISTE_USERS_ITEM_PER_PAGE
        : state.users.itemsPerPage) +
            (page && page !== 1 ? '&currentPage=' + page : '') +
            (searchTerm && searchTerm !== '' ? '&q=' + searchTerm : ''),
      false
    )
      .then((response: any) => {
        dispatch({
          type: Constants.GET_ALL_USERS,
          payload: {
            users: response.data,
            currentPage: page ? page : 1,
            totalUsers: response.headers['x-pagination-total-count'],
            itemsPerPage: Constants.LISTE_USERS_ITEM_PER_PAGE //response.headers['x-pagination-per-page']
          }
        });
        dispatch(loadingSpinner(false));
      })
      .catch(() => {});
  };
};

export const getUserTypes = () => {
  return (dispatch: any) => {
    dispatch(loadingSpinner(true));
    return Request.get('/userType', false)
      .then((response: any) => {
        dispatch({
          type: Constants.GET_USERS_TYPES,
          payload: {
            usersType: response.data
          }
        });
        dispatch(loadingSpinner(false));
      })
      .catch(() => {});
  };
};

export const addUser = (values: any) => {
  if (values.type === '') {
    delete values.type;
  }
  return (dispatch: any) => {
    dispatch(loadingSpinner(true));
    let idUser = '';
    return Request.post('/user', values)
      .then((response: any) => {
        //get User contracts
        const tmp = response.headers['location'].split('/');
        idUser = tmp[tmp.length - 1];
        dispatch(loadingSpinner(false));
        return idUser;
      })
      .catch(() => {});
  };
};
export const updateUser = (id: string, values: any) => {
  if (values.type === '') {
    delete values.type;
  }
  values['id'] = id;
  return (dispatch: any) => {
    dispatch(loadingSpinner(true));
    return Request.put('/user/' + id, values)
      .then((response: any) => {
        return dispatch(loadingSpinner(false));
      })
      .catch(() => {});
  };
};

export const addUserContract = (values: any) => {
  return (dispatch: any) => {
    dispatch(loadingSpinner(true));
    return Request.post('/userContract', values)
      .then((response: any) => {
        return dispatch(getUserContract(values.user));
      })
      .then(() => {
        //hide the loading spinner
        dispatch(loadingSpinner(false));
      })
      .catch(() => {});
  };
};

export const updateUserContract = (values: any) => {
  return (dispatch: any) => {
    return Request.put('/userContract/' + values.id, values)
      .then(() => {
        return dispatch(getUserContract(values.user));
      })
      .catch(() => {});
  };
};

export const deleteUserContract = (id: string) => {
  return (dispatch: any) => {
    dispatch(loadingSpinner(true));
    return Request.delete('/userContract/' + id).catch(() => {});
  };
};

export const getUserContract = (userId: string) => {
  return (dispatch: any) => {
    return Request.get('/user/' + userId + '/userContract', false)
      .then((response: any) => {
        //dispatch usersContracts
        dispatch({
          type: Constants.GET_USER_CONTRACTS,
          payload: {
            userId: userId,
            userContracts: response.data
          }
        });
        return response.data;
      })
      .catch(() => {});
  };
};
export const getUserById = (id: string) => {
  return (dispatch: any) => {
    dispatch(loadingSpinner(true));
    return Request.get('/user/' + id, false)
      .then((response: any) => {
        dispatch({
          type: Constants.GET_USER_BY_ID,
          payload: {
            user: response.data
          }
        });
        dispatch(loadingSpinner(false));
      })
      .catch(() => {});
  };
};
export const deleteUserById = (id: string) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    dispatch(loadingSpinner(true));
    return Request.delete('/user/' + id)
      .then((response: any) => {
        dispatch(getAllUsers(state.users.currentPage));
      })
      .catch(() => {});
  };
};
export const login = (token: string, authMode: AuthMode): any => ({
  type: Constants.LOG_IN,
  payload: {
    authMode: authMode,
    token: token,
    logged: true
  }
});
