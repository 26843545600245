import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./addUser.scss";
import { useHistory } from "react-router-dom";
import { SzButton, SzIcon, SzSpinner } from "@suezenv/react-theme-components";
import { Routes } from "../../constants";
import GoTo from "../elements/goTo";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SignupSchema = Yup.object().shape({
  type: Yup.string().required("Champ obligatoire"),
  username: Yup.string()
    .min(3, "le nom est trés court")
    .max(50, "le nom est trop long")
    .required("Champ obligatoire"),
  firstName: Yup.string()
    .min(3, "le nom est trop court")
    .max(50, "le nom est trop long")
    .required("Champ obligatoire"),
  lastName: Yup.string()
    .min(3, "le nom est trop court")
    .max(50, "le nom est trop long")
    .required("Champ obligatoire"),
  suezGid: Yup.string(),
  ldapDn: Yup.string(),
  mobilePhone: Yup.string().matches(
    phoneRegExp,
    "Numéro de télèphone n'est pas valide"
  ),
  phone: Yup.string().matches(
    phoneRegExp,
    "Numéro de télèphone n'est pas valide"
  ),
});
interface IAddUser {
  title: string;
  userId?: string;
  user?: any;
  usersType: any;
  saveUser: Function;
  getUserType: Function;
  updateUser?: Function;
  getUserById?: Function;
  getUserContract?: Function;
}
function AddUser(props: IAddUser) {
  const [contract, setContract] = useState({
    loading: false,
    userContracts: [],
    isContractVisible: false,
  });
  let history = useHistory();
  useEffect(() => {
    if (props.usersType.length === 0) {
      props.getUserType();
    }
    if (props.userId && props.getUserById) {
      props.getUserById(props.userId);
    }
  }, []);

  const contractList = () => {
    if (contract.loading) {
      return (
        <div className="row mb-4  justify-content-around">
          <div className="col-auto">
            <SzSpinner variant="primary" />
          </div>
        </div>
      );
    }
    if (!contract.isContractVisible) {
      return;
    }
    if (contract.userContracts && contract.userContracts.length === 0) {
      return (
        <div className="row mb-4  justify-content-around">
          <div className="col-auto">Pas de contrat pour cet utilisateur</div>
        </div>
      );
    }
    return (
      <div className="row mb-4 ml-2">
        {contract.userContracts &&
          contract.userContracts.map((item: any) => (
            <div className="col-4">
              <li> {item.contract.label}</li>
            </div>
          ))}
      </div>
    );
  };
  const onClickVisible = () => {
    if (contract.isContractVisible) {
      setContract({ ...contract, isContractVisible: false });
      return;
    }
    setContract({ ...contract, loading: true, isContractVisible: true });
    if (props.userId && props.getUserContract) {
      props.getUserContract(props.userId).then((response: any) => {
        setContract({
          ...contract,
          userContracts: response,
          loading: false,
          isContractVisible: true,
        });
      });
    }
  };
  return (
    <div className="add-user row  h-100 justify-content-around align-items-center">
      <div className="col-8">
        <GoTo
          link={Routes.usersList}
          title="Retour à la liste des utilisateurs"
        />
        <div className="row mb-3 justify-content-between">
          <div className="col-auto">
            <h1 className="title">{`${props.title} : ${
              props.userId
                ? props.user.lastName + " " + props.user.firstName
                : ""
            }`}</h1>
          </div>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={props.user}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            if (props.userId && props.updateUser) {
              props.updateUser(props.userId, values).then(() => {
                history.push(`/utilisateurs/${props.userId}/contrats`);
              });
            } else {
              props.saveUser(values).then((id: string) => {
                history.push(`/utilisateurs/${id}/contrats`);
              });
            }
          }}
        >
          {({ errors, touched, isValid }) => (
            <Form>
              {props.usersType.length > 0 && (
                <div className="row mb-2">
                  <div className="col">
                    <label className="m-0">Type : </label>
                    <Field
                      className={
                        errors.type && touched.type
                          ? "error pl-0 form-control"
                          : "pl-0 form-control"
                      }
                      as="select"
                      name="type"
                    >
                      <option value={""}>type d'utilisateur</option>
                      {props.usersType.map((item: any) => (
                        <option
                          key={item.id}
                          value={item.id}
                          selected={item.id === props.user.type}
                        >
                          {" "}
                          {item.label}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
              )}
              <div className="row mb-2">
                <div className="col">
                  <label className="m-0">
                    Identifient de l'utilisateur (login):{" "}
                  </label>
                  <Field
                    className={
                      errors.username && touched.username
                        ? "error pl-0 form-control"
                        : "pl-0 form-control"
                    }
                    name="username"
                  />
                  {errors.username && touched.username ? (
                    <div className="text-danger">{errors.username}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col">
                  <label className="m-0">Prénom: </label>
                  <Field
                    className={
                      errors.firstName && touched.firstName
                        ? "error pl-0 form-control"
                        : "pl-0 form-control"
                    }
                    name="firstName"
                  />
                  {errors.firstName && touched.firstName ? (
                    <div className="text-danger">{errors.firstName}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col">
                  <label className="m-0">Nom: </label>
                  <Field
                    name="lastName"
                    className={
                      errors.lastName && touched.lastName
                        ? "error pl-0 form-control"
                        : "pl-0 form-control"
                    }
                  />
                  {errors.lastName && touched.lastName ? (
                    <div className="text-danger">{errors.lastName}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col">
                  <label className="m-0">suezGid: </label>
                  <Field
                    name="suezGid"
                    className={
                      errors.suezGid && touched.suezGid
                        ? "error pl-0 form-control"
                        : "pl-0 form-control"
                    }
                  />
                  {errors.suezGid && touched.suezGid ? (
                    <div className="text-danger">{errors.suezGid}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col">
                  <label className="m-0">ldapDn: </label>
                  <Field
                    name="ldapDn"
                    className={
                      errors.ldapDn && touched.ldapDn
                        ? "error pl-0 form-control"
                        : "pl-0 form-control"
                    }
                  />
                  {errors.ldapDn && touched.ldapDn ? (
                    <div className="text-danger">{errors.ldapDn}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col">
                  <label className="m-0">ldapUidNumber: </label>
                  <Field
                    name="ldapUidNumber"
                    className={
                      errors.ldapDn && touched.ldapDn
                        ? "error pl-0 form-control"
                        : "pl-0 form-control"
                    }
                  />
                  {errors.ldapUidNumber && touched.ldapUidNumber ? (
                    <div className="text-danger">{errors.ldapUidNumber}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col">
                  <label className="m-0">Numéro de télèphone: </label>
                  <Field
                    name="phone"
                    className={
                      errors.phone && touched.phone
                        ? "error pl-0 form-control"
                        : "pl-0 form-control"
                    }
                  />
                  {errors.phone && touched.phone ? (
                    <div className="text-danger">{errors.phone}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col">
                  <label className="m-0">Numéro de télèphone personnel: </label>
                  <Field
                    name="mobilePhone"
                    className={
                      errors.mobilePhone && touched.mobilePhone
                        ? "error pl-0 form-control"
                        : "pl-0 form-control"
                    }
                  />
                  {errors.mobilePhone && touched.mobilePhone ? (
                    <div className="text-danger">{errors.mobilePhone}</div>
                  ) : null}
                </div>
              </div>
              {props.userId && (
                <div
                  id="contract-controller"
                  className="row justify-content-start"
                >
                  <div className="col-auto">
                    <SzButton
                      className="transparent pl-0 mb-2 control-collapse"
                      onClick={onClickVisible}
                      icon={
                        contract.isContractVisible
                          ? "subtract-square"
                          : "add-square"
                      }
                    >
                      {contract.isContractVisible
                        ? "Masquer les contrats"
                        : "Afficher les contrats"}
                    </SzButton>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col">{contractList()}</div>
              </div>
              <div className="row mb-2">
                <div className={props.userId ? "col-4" : "col-5"}>
                  <SzButton type="submit">Sauvegarder et continuer</SzButton>
                </div>
                {props.userId && (
                  <>
                    <div className="col-4">
                      <SzButton
                        onClick={() => {
                          if (props.userId) {
                            history.push(
                              Routes.userContract.replace("{id}", props.userId)
                            );
                          }
                        }}
                      >
                        Continuer sans sauvegarder
                      </SzButton>
                    </div>
                    <div className="col-3 offset-1">
                      <SzButton
                        onClick={() => {
                          history.push("/");
                        }}
                        variant="secondary"
                      >
                        Annuler
                      </SzButton>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
export default AddUser;
