import Request from "../services/Request";
import { Constants } from "../constants";
import { metadataFilterContract } from "../types";

export const getContracts = (
  query: string,
  clientId: string,
  metadata: metadataFilterContract | null
) => {
  return (dispatch: any) => {
    dispatch({
      type: Constants.LOADING_CONTRACT,
      payload: {
        loadingContract: true,
      },
    });
    dispatch({
      type: Constants.GET_CONTRACT_AREA,
      payload: {
        contractAreas: [],
      },
    });
    let uri =
      "/contract?" +
      (query ? "q=" + query : "") +
      (clientId ? "&client=" + clientId : "");
    if (metadata && metadata.key && metadata.value) {
      uri =
        uri +
        "&contractMetadatas[key][]=" +
        metadata.key +
        "&contractMetadatas[value][]=" +
        metadata.value +
        "&contractMetadatasSearchMode=non-strict";
    }
    return Request.get(uri, false)
      .then((response: any) => {
        dispatch({
          type: Constants.GET_ALL_CONTRACTS,
          payload: {
            contracts: response.data,
          },
        });
        dispatch({
          type: Constants.LOADING_CONTRACT,
          payload: {
            loadingContract: false,
          },
        });
      })
      .catch(() => {});
  };
};

export const getContractAreas = (contractId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: Constants.LOADING_CONTRACT_AREA,
      payload: {
        loadingContractArea: true,
      },
    });
    return Request.get("/contract/" + contractId + "/contractArea", false)
      .then((response: any) => {
        dispatch({
          type: Constants.GET_CONTRACT_AREA,
          payload: {
            contractAreas: response.data,
          },
        });
        dispatch({
          type: Constants.LOADING_CONTRACT_AREA,
          payload: {
            loadingContractArea: false,
          },
        });
      })
      .catch(() => {});
  };
};
export const getUserContractService = (userContractId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: Constants.LOADING_CONTRACT_SERVICE,
      payload: {
        loadingContractService: true,
      },
    });
    return Request.get(
      "/userContract/" + userContractId + "/userContractService",
      false
    )
      .then((response: any) => {
        dispatch({
          type: Constants.GET_USER_CONTRACT_SERVICE,
          payload: {
            userContractService: response.data,
          },
        });
        dispatch({
          type: Constants.LOADING_CONTRACT_SERVICE,
          payload: {
            loadingContractService: false,
          },
        });
      })
      .catch(() => {});
  };
};
export const getUserContractServiceOptions = (userContractId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: Constants.LOADING_CONTRACT_SERVICE,
      payload: {
        loadingContractService: true,
      },
    });
    return Request.get("/contract/" + userContractId + "/serviceOption", false)
      .then((response: any) => {
        dispatch({
          type: Constants.GET_USER_CONTRACT_SERVICE_OPTION,
          payload: {
            userContractServiceOptions: response.data,
          },
        });
        dispatch({
          type: Constants.LOADING_CONTRACT_SERVICE,
          payload: {
            loadingContractService: false,
          },
        });
      })
      .catch(() => {});
  };
};
export const getContractService = (contractId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: Constants.LOADING_CONTRACT_SERVICE,
      payload: {
        loadingContractService: true,
      },
    });
    return Request.get("/contract/" + contractId + "/contractService", false)
      .then((response: any) => {
        dispatch({
          type: Constants.GET_CONTRACT_SERVICE,
          payload: {
            contractService: response.data,
          },
        });
      })
      .catch(() => {});
  };
};
export const getContractServiceOption = (contractId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: Constants.LOADING_CONTRACT_SERVICE,
      payload: {
        loadingContractService: true,
      },
    });
    return Request.get(
      "/contract/" + contractId + "/contractServiceOption",
      false
    )
      .then((response: any) => {
        dispatch({
          type: Constants.GET_CONTRACT_SERVICE_OPTION,
          payload: {
            contractServiceOption: response.data,
          },
        });
      })
      .catch(() => {});
  };
};
export const getListUserContractService = () => {
  return (dispatch: any) => {
    dispatch({
      type: Constants.LOADING_CONTRACT_SERVICE,
      payload: {
        loadingContractService: true,
      },
    });
    return Request.get("/userContractService", false)
      .then((response: any) => {
        dispatch({
          type: Constants.GET_CONTRACT_SERVICE_LIST,
          payload: {
            userContractServiceSavedList: response.data,
          },
        });
        dispatch({
          type: Constants.LOADING_CONTRACT_SERVICE,
          payload: {
            loadingContractService: false,
          },
        });
      })
      .catch(() => {});
  };
};
export const postUserContractService = (body: any) => {
  return (dispatch: any) => {
    return Request.post("/userContractService", body).catch(() => {});
  };
};
export const putUserContractService = (
  body: any,
  userContractServiceId: string
) => {
  return (dispatch: any) => {
    return Request.put(
      "/userContractService/" + userContractServiceId,
      body
    ).catch(() => {});
  };
};
export const deleteUserContractService = (userContractServiceId: string) => {
  return (dispatch: any) => {
    return Request.delete(
      "/userContractService/" + userContractServiceId
    ).catch(() => {});
  };
};
