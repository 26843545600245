import React, { useState } from "react";
import { SzIcon, SzButton, SzInput } from "@suezenv/react-theme-components";
import "./Search.scss";

interface ISuggestion {
  farms: {
    id: string;
    description: string;
  }[];
  cities: {
    code: string;
    description: string;
  }[];
}
interface ISearch {
  search?: Function;
  placeholder: string;
}
function Search(props: ISearch) {
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <div className="row">
      <div className="col">
        <SzInput
          value={searchTerm}
          type="text"
          onChange={(event) => setSearchTerm(event.target.value)}
        />
      </div>
      <div className="col-auto" id="button-addon4">
        <SzButton
          className="btn btn-close"
          type="button"
          onClick={() => {
            setSearchTerm("");
            if (props.search) props.search("");
          }}
        >
          <span>
            <SzIcon icon="remove" variant="line" />
          </span>
        </SzButton>
        {props.search !== undefined && (
          <SzButton
            className="mt-0 ml-1"
            variant="secondary"
            type="submit"
            onClick={() => {
              if (props.search) props.search(searchTerm);
            }}
            icon="search"
          />
        )}
      </div>
    </div>
  );
}

export default Search;
