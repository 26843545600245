import React, { useState, useRef } from "react";
import { SzButton } from "@suezenv/react-theme-components";
import './usersImport.scss'

interface IUsersImport {
    title: string;
    importUsers: Function;
}

function UsersImport(props: IUsersImport) {
  
    const [fileToImport, setFileToImport] = useState<null|File>(null);

    const inputFile = useRef<any>(null);

    const onFileSelectHandler = (e: any) => {
        const element = e.target as HTMLInputElement;
        if (element.files && element.files.length > 0) {
            setFileToImport(element.files[0] as File);
        } else {
            setFileToImport(null);
        }
    }

    const onClickImportHandler = async () =>  {
        if (fileToImport) {
            await props.importUsers(fileToImport);
            setFileToImport(null);

            // reset input file element
            if (inputFile.current) {
                inputFile.current.value = "";
                inputFile.current.type = "file";
            }
        }
    }
    
    return (
        <div className="row import h-100 justify-content-around">
            <div className="col-12">
                <div className="row mb-4">
                    <div className="col-auto">
                        <h1 className="title">{`${props.title}`}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <input type="file" name="filename" ref={inputFile} onChange={onFileSelectHandler}></input>
                    </div>
                    <div className="col-auto ml-5">
                        <SzButton onClick={onClickImportHandler}>Importer</SzButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersImport;
