// action strings
export enum Constants {
    GET_ALL_USERS = "GET_ALL_USERS",
    GET_USER_BY_ID = "GET_USER_BY_ID",
    GET_USERS_TYPES = "GET_USERS_TYPES",
    DATA_LOADING = "DATA_LOADING",
    GET_ALL_CONTRACTS = "GET_ALL_CONTRACTS",
    GET_USER_CONTRACTS = "GET_USER_CONTRACTS",
    GET_ALL_CLIENTS = "GET_ALL_CLIENTS",
    GET_CONTRACT_AREA = "GET_CONTRACT_AREA",
    LOADING_CONTRACT = "LOADING_CONTRACT",
    LOADING_CONTRACT_AREA = 'LOADING_CONTRACT_AREA',
    LOADING_CONTRACT_SERVICE = 'LOADING_CONTRACT_SERVICE',
    GET_USER_CONTRACT_SERVICE = 'GET_USER_CONTRACT_SERVICE',
    GET_USER_CONTRACT_SERVICE_OPTION = 'GET_USER_CONTRACT_SERVICE_OPTION',
    GET_CONTRACT_SERVICE = 'GET_CONTRACT_SERVICE',
    GET_CONTRACT_SERVICE_LIST = 'GET_CONTRACT_SERVICE_LIST',
    GET_CONTRACT_SERVICE_OPTION = 'GET_CONTRACT_SERVICE_OPTION',
    ERROR = "ERROR",
    LOG_IN = "LOG_IN",
    LOG_OUT = "LOG_OUT",
    LISTE_USERS_ITEM_PER_PAGE = "20",

}

export enum ERROR {
    SET = "ERROR.SET",
}
export enum AUTH_CODE {
    USER_NOT_AUTHENTICATED = "USER_NOT_AUTHENTICATED",
    USER_AUTHENTICATED = "USER_AUTHENTICATED"
}
export enum Routes {
    usersList = '/utilisateurs',
    addUser = '/utilisateurs/ajouter',
    updateUser = '/utilisateurs/modifier',
    userContract = '/utilisateurs/{id}/contrats',
    usersImport = '/utilisateurs/importer',
    rightsAssignment = '/utilisateurs/copie-droits'
}
export const Patterns = {
    emailPattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}