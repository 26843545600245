import React, { useState } from 'react';
import { importUsers } from '../../actions';
import { connect } from 'react-redux';
import UsersImport from '../../components/usersImport';
import { withRouter } from 'react-router-dom';
import Loading from '../../components/elements/loading';
import { MessageBox, IMessageBox } from '../../components/elements/messageBox';
import { SzTable } from '@suezenv/react-theme-components';

const columns = [
    {
      dataField: "index",
      text: "N°",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "status",
      text: "Status ",
    },
    {
      dataField: "message",
      text: "Message",
    }
];

const UsersImportManager =  (props: any) => {

    const [resultTable, setResultTable] = useState<any>(null);
    const [messageBox, setMessageBox] = useState<IMessageBox>({
        type: undefined,
        message: ''
    });

    const importUsersHandler = async (file: File) => {
        setResultTable(null);
        const formData = new FormData();
        formData.append("file", file);

        try {
            const result = await props.importUsers(formData);
            const data = result.data.map((item: object, key: number) => {
                return { index: (key+1), ...item }
            });

            setResultTable(<SzTable
                className="table-striped"
                data={data}
                keyField={"index"}
                columns={columns}
            />);

            setMessageBox({ type: 'successBox', message: 'Utilisateurs importés avec succès !'});
        }
        catch(err: any) {
            let response = err.response?.data ? err.response.data : "Une erreur est survenue lors de l'oppération";
            if(typeof response === 'object' && 'message' in response) {
                response = response.message;
            }
            
            setMessageBox({ type: 'dangerBox', message: response});
        }
    };

    return (
        <>
            {props.loading.loading && <Loading></Loading>}
            {props.logged && (
                <div className="container mt-5">
                    <UsersImport
                        title={'Importer des utilisateurs'}
                        importUsers={importUsersHandler}
                    ></UsersImport>
                    {messageBox.type && <MessageBox {...messageBox} />}
                    {resultTable}
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.loading,
    logged: state.auth.logged,
});

const mapDispatchToProps = {
    importUsers
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UsersImportManager)
);
