import React from "react";
import { SzTypographie, SzButton } from "@suezenv/react-theme-components";
interface IDeleteModalBody {
  title: string;
  onClickDelete: () => void;
  onClickCancel: () => void;
}
const DeleteModalBody = (props: IDeleteModalBody) => {
  return (
    <>
      <div className="row mt-3">
        <div className="col">
          <SzTypographie color="primary" variant="h1" weight="medium">
            {props.title}
          </SzTypographie>
        </div>
      </div>
      <div className="row justify-content-end">
        <div className="col-auto">
          <SzButton onClick={props.onClickDelete}>Supprimer</SzButton>
        </div>
        <div className="col-auto">
          <SzButton onClick={props.onClickCancel} variant="secondary">
            Annuller
          </SzButton>
        </div>
      </div>
    </>
  );
};
export default DeleteModalBody;
