import React, { useEffect, useState } from "react";
import { SzButton, SzIcon } from "@suezenv/react-theme-components";
import { Patterns } from '../../constants/index';

export interface IRightsAssignment {
    userSource: string
    userDestination: string
}

export const RightsAssignment = (props: any) => {
  
    const [disable, setDisable] = useState(true);
    const [users, setUsers] = useState<IRightsAssignment>({
        userSource: '',
        userDestination: ''
    });

    useEffect(() => {
        setDisable(false);

        // each user email should be valid and different
        if(
            users.userSource.length == 0 ||
            users.userDestination.length == 0 ||
            Patterns.emailPattern.test(users.userSource) == false ||
            Patterns.emailPattern.test(users.userDestination) == false ||
            users.userSource == users.userDestination
        )
        {
            setDisable(true);
        }
    }, [users])

    const handleChange = ({ currentTarget } : any) => {
        const { value, name } = currentTarget;
        setUsers({ ...users, [name]: value });
    };

    const onClickSendHandler = async () =>  {
        await props.assignUserRights(users);
    }
    
    return (
        <div className="row h-100 justify-content-around">
            <div className="col-12">
                <div className="row mb-4">
                    <div className="col-auto">
                        <h1 className="title">{`${props.title}`}</h1>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row mb-4">
                    <div className="col-5">
                        <label className="m-0">Utilisateur source</label>
                        <input type="email" name="userSource" value={users.userSource} onChange={handleChange} className="form-control"></input>
                    </div>
                    <div className="col-2">
                        <label className="m-0"></label>
                        <div style={{textAlign: 'center'}}>
                            <SzIcon icon="arrow-right" variant="line" className="mr-2" />
                            <SzIcon icon="arrow-right" variant="line" className="mr-2" />
                            <SzIcon icon="arrow-right" variant="line" />
                        </div>
                    </div>
                    <div className="col-5">
                        <label className="m-0">Utilisateur destination</label>
                        <input type="email" name="userDestination" value={users.userDestination} onChange={handleChange} className="form-control"></input>
                    </div>
                </div>
            </div>
            <div className="row col-12 justify-content-end">
                <SzButton onClick={onClickSendHandler} isDisabled={disable}>Envoyer</SzButton>
            </div>
        </div>
    );
}