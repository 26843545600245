import React, { useState, useEffect } from 'react';
import Users from '../../components/users';
import {
  getAllUsers,
  addUser,
  getUserTypes,
  getUserById,
  updateUser,
  getUserContract,
  deleteUserById
} from '../../actions';
import { connect } from 'react-redux';
import AddUser from '../../components/addUser';
import { withRouter } from 'react-router-dom';
import Loading from '../../components/elements/loading';
import { Routes } from '../../constants';
const initialUser = {
  type: '',
  username: '',
  firstName: '',
  lastName: '',
  suezGid: '',
  ldapDn: '',
  ldapUidNumber: '',
  civility: '',
  function: '',
  phone: '',
  mobilePhone: ''
};
const UsersManager = (props: any) => {
  const [state, setState] = useState({
    user: undefined
  });
  const updateUser = (user: any) => {
    setState(() => ({
      user: user
    }));
  };
  const saveUser = (values: any) => {
    return props.addUser(values);
  };
  const filterUsers = (searchTerm: string) => {
    props.getAllUsers(1, searchTerm);
  };
  useEffect(() => {
    const pathname = props.location.pathname;
    if (props.logged && pathname === Routes.usersList) {
      props.getAllUsers();
    }
  }, [props.logged, props.location.pathname]);
  return (
    <>
      {props.loading.loading && <Loading></Loading>}
      {props.logged && (
        <div className="container mt-5 h-75">
          {props.location.pathname === Routes.usersList && (
            <Users
              totalUsers={props.users.totalUsers}
              currentPage={props.users.currentPage}
              users={props.users.users}
              pageChange={props.getAllUsers}
              itemsPerPage={props.users.itemsPerPage}
              filterUsers={filterUsers}
              updateUser={updateUser}
              deleteUser={props.deleteUserById}
            ></Users>
          )}
          {props.location.pathname === Routes.addUser && (
            <AddUser
              title={'Ajouter un utilisateur'}
              user={initialUser}
              usersType={props.users.usersType}
              getUserType={props.getUserTypes}
              saveUser={saveUser}
            ></AddUser>
          )}
          {props.location.pathname.includes(Routes.updateUser) && (
            <AddUser
              title={"Modifier l'utilisateur"}
              userId={props.match.params.id}
              user={JSON.parse(
                JSON.stringify(props.users.user).replace(/null/g, '""')
              )}
              updateUser={props.updateUser}
              getUserById={props.getUserById}
              usersType={props.users.usersType}
              getUserType={props.getUserTypes}
              saveUser={saveUser}
              getUserContract={props.getUserContract}
            ></AddUser>
          )}
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  users: state.users,
  router: state.router,
  loading: state.loading,
  contracts: state.contracts,
  logged: state.auth.logged,
  authMode: state.auth.authMode
});
const mapDispatchToProps = {
  getAllUsers,
  addUser,
  getUserTypes,
  getUserById,
  updateUser,
  getUserContract,
  deleteUserById
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersManager)
);
