import React from "react";
import { NavLink } from 'react-router-dom';
import "./navBar.scss";

export const NavBar = (props: any) => {
    return (
        <div className="nav-bar">
            <ul>
                <li><NavLink exact={true} to={'/utilisateurs'}>Liste utilisateurs</NavLink></li>
                <li><NavLink exact={true} to={'/utilisateurs/importer'}>Importer utilisateurs</NavLink></li>
                <li><NavLink exact={true} to={'/utilisateurs/copie-droits'}>Copie de droits</NavLink></li>
            </ul>
        </div>
    );
};

